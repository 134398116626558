<template>
  <el-dialog
    title="新增空间用户"
    :visible.sync="showDialog"
    width="400px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <el-form
      :model="form"
      ref="ruleForm"
      status-icon
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="空间用户：" prop="userId">
        <el-select
          v-model="form.userId"
          placeholder="请输入关键字"
          clearable
          filterable
          remote
          reserve-keyword
          :remote-method="filterUsers"
          @change="changeSelectFn($event)"
        >
          <el-option
            v-for="(item, idx) in usersData"
            :key="idx"
            :label="item.nickname"
            :value="item.userId.toString()"
            :disabled="item.checked"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span></el-dialog
  >
</template>

<script>
export default {
  name: 'add_role',
  props: {
    showDialog: Boolean,
    userList: Array
  },
  data() {
    return {
      form: { userId: '' },
      rules: {
        userId: [
          { required: true, message: '请选择空间用户', trigger: 'change' }
        ]
      },
      usersData: []
    };
  },
  mounted() {},
  watch: {
    userList(v) {
      this.usersData = [...v];
    }
  },
  methods: {
    changeSelectFn(e) {
      if (e == '') {
        this.usersData = [...this.userList];
      }
    },
    initData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.form.userId = '';
        this.form.username = '';
      });
    },
    filterUsers(query) {
      if (query !== '') {
        setTimeout(() => {
          this.usersData = this.userList.filter(item => {
            let has_nickname =
              item.nickname.toLowerCase().indexOf(query.toLowerCase()) > -1;
            let has_username =
              item.username.toLowerCase().indexOf(query.toLowerCase()) > -1;
            let has_email = false;
            if (item.email && item.email != '') {
              has_email =
                item.email.toLowerCase().indexOf(query.toLowerCase()) > -1;
            } else {
              has_email = false;
            }
            let has_cellphone = false;
            if (item.cellphone && item.cellphone != '') {
              has_cellphone = item.cellphone.indexOf(query) > -1;
            } else {
              has_cellphone = false;
            }
            return has_nickname || has_username || has_email || has_cellphone;
          });
        }, 200);
      } else {
        this.usersData = [];
      }
    },
    closeFn() {
      this.$emit('closeDialog');
    },
    sureFn() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('sureDialog', this.form);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.inputWidth {
  width: 350px;
  margin-right: 15px;
}
</style>
